import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from './Axios';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  // Retrieve token from localStorage on component mount
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  // Fetch current user when the token is set
  useEffect(() => {
    const fetchCurrentUser = async () => {
      if (token) {
        try {
          const response = await axiosInstance.get('/v1/users/current', {
            headers: { Authorization: `JWT ${token}` },
          });
          setCurrentUser(response.data);
          console.log(response.data);
        } catch (error) {
          console.error('Error fetching current user:', error);
        }
      }
    };

    fetchCurrentUser();
  }, [token]);

  const login = (token) => {
    localStorage.setItem('token', token);
    setToken(token);
  };

  const logout = () => {
    console.log('Logging out');
    setToken(null);
    setCurrentUser(null);
    localStorage.removeItem('token');
  };

  return (
    <AuthContext.Provider value={{ token, login, logout, currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
