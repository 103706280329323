import React, { useState, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '1000px'
};

const center = {
  lat: 30.1502,
  lng: 71.3868
};

function MapComponent({ meters }) {
  console.log(meters);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyB1JJR4G1rmNjnkP4D0LvGD8dBgaUSk_N4"
  });

  const [map, setMap] = useState(null);
  const [selectedMeter, setSelectedMeter] = useState(null);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    meters.forEach(meter => {
      bounds.extend(new window.google.maps.LatLng(meter.LATITUDE, meter.LONGITUDE));
    });
    map.fitBounds(bounds);

    setMap(map);
  }, [meters]);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {meters.map(meter => (
        <Marker
          key={meter.id}
          position={{
            lat: parseFloat(Number(meter.LATITUDE).toFixed(6)),
            lng: parseFloat(Number(meter.LONGITUDE).toFixed(6))
          }}
          onClick={() => setSelectedMeter(meter)}
        />
      ))}

      {selectedMeter && (
        <InfoWindow
        position={{
          lat: parseFloat(Number(selectedMeter.LATITUDE).toFixed(6)),
          lng: parseFloat(Number(selectedMeter.LONGITUDE).toFixed(6))
        }}
          onCloseClick={() => setSelectedMeter(null)}
        >
          <div>
      <h4>Meter Details</h4>
      <p><strong>NEW METER NUMBER:</strong> {selectedMeter.NEW_METER_NUMBER}</p>
      <p><strong>REF NO:</strong> {selectedMeter.REF_NO}</p>
      <p><strong>METER STATUS:</strong> {selectedMeter.METER_STATUS}</p>
      <p><strong>OLD METER NUMBER:</strong> {selectedMeter.OLD_METER_NUMBER}</p>
      <p><strong>OLD METER READING:</strong> {selectedMeter.OLD_METER_READING}</p>
      <p><strong>NEW METER READING:</strong> {selectedMeter.NEW_METER_READING}</p>
      <p><strong>CONNECTION TYPE:</strong> {selectedMeter.CONNECTION_TYPE}</p>
      <p><strong>BILL MONTH:</strong> {selectedMeter.BILL_MONTH}</p>
      <p><strong>LATITUDE:</strong> {selectedMeter.LATITUDE}</p>
      <p><strong>LONGITUDE:</strong> {selectedMeter.LONGITUDE}</p>
      <p><strong>METER TYPE:</strong> {selectedMeter.METER_TYPE}</p>
      <p><strong>KWH MF:</strong> {selectedMeter.KWH_MF}</p>
      <p><strong>SAN LOAD:</strong> {selectedMeter.SAN_LOAD}</p>
      <p><strong>CONSUMER NAME:</strong> {selectedMeter.CONSUMER_NAME}</p>
      <p><strong>CONSUMER ADDRESS:</strong> {selectedMeter.CONSUMER_ADDRESS}</p>
      <p><strong>QC CHECK:</strong> {selectedMeter.QC_CHECK ? "Yes" : "No"}</p>
      <p><strong>APPLICATION NO:</strong> {selectedMeter.APPLICATION_NO}</p>
      <p><strong>GREEN METER:</strong> {selectedMeter.GREEN_METER}</p>
      <p><strong>TELCO:</strong> {selectedMeter.TELCO}</p>
      <p><strong>SIM NO:</strong> {selectedMeter.SIM_NO}</p>
      <p><strong>SIGNAL STRENGTH:</strong> {selectedMeter.SIGNAL_STRENGTH}</p>
      <p><strong>METER REPLACE DATE TIME:</strong> {selectedMeter.METR_REPLACE_DATE_TIME}</p>
      <p><strong>NO OF RESET OLD METER:</strong> {selectedMeter.NO_OF_RESET_OLD_METER}</p>
      <p><strong>NO OF RESET NEW METER:</strong> {selectedMeter.NO_OF_RESET_NEW_METER}</p>
      <p><strong>KWH T1:</strong> {selectedMeter.KWH_T1}</p>
      <p><strong>KWH T2:</strong> {selectedMeter.KWH_T2}</p>
      <p><strong>KWH TOTAL:</strong> {selectedMeter.KWH_TOTAL}</p>
      <p><strong>KVARH T1:</strong> {selectedMeter.KVARH_T1}</p>
      <p><strong>KVARH T2:</strong> {selectedMeter.KVARH_T2}</p>
      <p><strong>KVARH TOTAL:</strong> {selectedMeter.KVARH_TOTAL}</p>
      <p><strong>MDI T1:</strong> {selectedMeter.MDI_T1}</p>
      <p><strong>MDI T2:</strong> {selectedMeter.MDI_T2}</p>
      <p><strong>MDI TOTAL:</strong> {selectedMeter.MDI_TOTAL}</p>
      <p><strong>CUMULATIVE MDI T1:</strong> {selectedMeter.CUMULATIVE_MDI_T1}</p>
      <p><strong>CUMULATIVE MDI T2:</strong> {selectedMeter.CUMULATIVE_MDI_T2}</p>
      <p><strong>CUMULATIVE MDI TOTAL:</strong> {selectedMeter.CUMULATIVE_MDI_TOTAL}</p>
    </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : <></>
}

export default React.memo(MapComponent);
