import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../utils/Axios';
import './inspectionDetails.css'; 
const InspectionDetails = () => {
    const { meterId } = useParams();
    const [inspection, setInspection] = useState(null);

    useEffect(() => {
        axiosInstance.get(`/v1/inspections/${meterId}`)
            .then(response => setInspection(response.data))
            .catch(error => console.error('Error fetching inspection:', error));
    }, [meterId]);

    if (!inspection) {
        return <p>Loading inspection details...</p>;
    }

    return (
        <div className="inspection-details">
            <h2>Inspection Details for Meter {inspection.meter_ref_name}</h2>
            <p><strong>QC Incharge:</strong> {inspection.qc_incharge}</p>
            <p><strong>Meter Install User:</strong> {inspection.meter_install_user}</p>
            <p><strong>Meter Type OK:</strong> {inspection.meter_type_ok ? 'Yes' : 'No'}</p>
            <p><strong>Display Verification OK:</strong> {inspection.display_verification_ok ? 'Yes' : 'No'}</p>
            <p><strong>Installation Location OK:</strong> {inspection.installation_location_ok ? 'Yes' : 'No'}</p>
            <p><strong>Wiring Connection OK:</strong> {inspection.wiring_connection_ok ? 'Yes' : 'No'}</p>
            <p><strong>Sealing OK:</strong> {inspection.sealing_ok ? 'Yes' : 'No'}</p>
            <p><strong>Documentation OK:</strong> {inspection.documentation_ok ? 'Yes' : 'No'}</p>
            <p><strong>Compliance Assurance OK:</strong> {inspection.compliance_assurance_ok ? 'Yes' : 'No'}</p>
            <p><strong>Remarks:</strong> {inspection.remarks}</p>
        </div>
    );
};

export default InspectionDetails;
