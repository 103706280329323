import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/Axios";
import cable from "actioncable";
import "./LiveMeterList.css";

const LiveMeterList = () => {
  const [meters, setMeters] = useState([]);

  useEffect(() => {
    const fetchMeters = async () => {
      try {
        const response = await axiosInstance.get("/v1/meters/edited_meters");
        setMeters(response.data);
      } catch (error) {
        console.error("Error fetching meters:", error);
      }
    };

    fetchMeters();

    const cableApp = cable.createConsumer("ws://50.62.183.243/api/cable");
    const subscription = cableApp.subscriptions.create("MeterChannel", {
      received: (updatedMeter) => {
        console.log("Received updated meter:", updatedMeter);
        setMeters((prevMeters) => {
          const meterExists = prevMeters.some(
            (meter) => meter.id === updatedMeter.id
          );
          let newMeters;

          if (meterExists) {
            newMeters = prevMeters.map((meter) =>
              meter.id === updatedMeter.id ? updatedMeter : meter
            );
          } else {
            console.log("Adding new meter record");
            newMeters = [updatedMeter, ...prevMeters];
          }

          // Sort by updated_at in descending order
          newMeters.sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
          );
          return newMeters;
        });
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      {meters.length > 0 ? (
        <div className="meter-table-container">
          <table className="meter-data-table">
            <thead>
              <tr>
                <th>Serial No</th>
                <th>New Meter Number</th>
                <th>Reference No</th>
                <th>Meter Status</th>
                <th>Old Meter Number</th>
                <th>Old Meter Reading</th>
                <th>New Meter Reading</th>
                <th>Connection Type</th>
                <th>Bill Month</th>
                <th>Longitude</th>
                <th>Latitude</th>
                <th>Meter Type</th>
                <th>KWH MF</th>
                <th>Sanctioned Load</th>
                <th>Consumer Name</th>
                <th>Consumer Address</th>
                <th>Application No</th>
                <th>Green Meter</th>
                <th>Telco</th>
                <th>SIM No</th>
                <th>Signal Strength</th>
                <th>Picture Upload</th>
                <th>Previous Meter Picture</th>
                <th>Meter Replace DateTime</th>
                <th>No of Reset Old Meter</th>
                <th>No of Reset New Meter</th>
                <th>KWH T1</th>
                <th>KWH T2</th>
                <th>KWH Total</th>
                <th>KVARH T1</th>
                <th>KVARH T2</th>
                <th>KVARH Total</th>
                <th>MDI T1</th>
                <th>MDI T2</th>
                <th>MDI Total</th>
                <th>Cumulative MDI T1</th>
                <th>Cumulative MDI T2</th>
                <th>Cumulative MDI Total</th>
                <th>New KWH T1</th>
                <th>New KWH T2</th>
                <th>New KVARH Total</th>
                <th>New KVARH T1</th>
                <th>New KVARH T2</th>
                <th>New MDI Total</th>
                <th>New MDI T1</th>
                <th>New MDI T2</th>
                <th>New Cumulative MDI Total</th>
                <th>New Cumulative MDI T1</th>
                <th>New Cumulative MDI T2</th>
                <th>New SIM No</th>
              </tr>
            </thead>
            <tbody>
              {meters.map((meter, index) => (
                <tr key={meter.id}>
                  <td>{index + 1}</td> {/* Serial Number */}
                  <td>{meter.NEW_METER_NUMBER}</td>
                  <td>{meter.REF_NO}</td>
                  <td>{meter.METER_STATUS}</td>
                  <td>{meter.OLD_METER_NUMBER}</td>
                  <td>{meter.OLD_METER_READING}</td>
                  <td>{meter.NEW_METER_READING}</td>
                  <td>{meter.CONNECTION_TYPE}</td>
                  <td>{meter.BILL_MONTH}</td>
                  <td>{meter.LONGITUDE}</td>
                  <td>{meter.LATITUDE}</td>
                  <td>{meter.METER_TYPE}</td>
                  <td>{meter.KWH_MF}</td>
                  <td>{meter.SAN_LOAD}</td>
                  <td>{meter.CONSUMER_NAME}</td>
                  <td>{meter.CONSUMER_ADDRESS}</td>
                  <td>{meter.APPLICATION_NO}</td>
                  <td>{meter.GREEN_METER}</td>
                  <td>{meter.TELCO}</td>
                  <td>{meter.SIM_NO}</td>
                  <td>{meter.SIGNAL_STRENGTH}</td>
                  <td>
                    {meter.PICTURE_UPLOAD && (
                      <a
                        href={meter.PICTURE_UPLOAD}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Image
                      </a>
                    )}
                  </td>
                  <td>
                    {meter.PREVIOUS_METER_PICTURE && (
                      <a
                        href={meter.PREVIOUS_METER_PICTURE}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Image
                      </a>
                    )}
                  </td>
                  <td>{meter.METR_REPLACE_DATE_TIME}</td>
                  <td>{meter.NO_OF_RESET_OLD_METER}</td>
                  <td>{meter.NO_OF_RESET_NEW_METER}</td>
                  <td>{meter.KWH_T1}</td>
                  <td>{meter.KWH_T2}</td>
                  <td>{meter.KWH_TOTAL}</td>
                  <td>{meter.KVARH_T1}</td>
                  <td>{meter.KVARH_T2}</td>
                  <td>{meter.KVARH_TOTAL}</td>
                  <td>{meter.MDI_T1}</td>
                  <td>{meter.MDI_T2}</td>
                  <td>{meter.MDI_TOTAL}</td>
                  <td>{meter.CUMULATIVE_MDI_T1}</td>
                  <td>{meter.CUMULATIVE_MDI_T2}</td>
                  <td>{meter.CUMULATIVE_MDI_Total}</td>
                  <td>{meter.NEW_KWH_T1}</td>
                  <td>{meter.NEW_KWH_T2}</td>
                  <td>{meter.NEW_KVARH_TOTAL}</td>
                  <td>{meter.NEW_KVARH_T1}</td>
                  <td>{meter.NEW_KVARH_T2}</td>
                  <td>{meter.NEW_MDI_TOTAL}</td>
                  <td>{meter.NEW_MDI_T1}</td>
                  <td>{meter.NEW_MDI_T2}</td>
                  <td>{meter.NEW_CUMULATIVE_MDI_TOTAL}</td>
                  <td>{meter.NEW_CUMULATIVE_MDI_T1}</td>
                  <td>{meter.NEW_CUMULATIVE_MDI_T2}</td>
                  <td>{meter.NEW_SIM_NO}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No meters found based on filters.</p>
      )}
    </>
  );
};

export default LiveMeterList;
