import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/Axios';
import { Link } from 'react-router-dom';
import './LiveMeterList.css';

const QCRejectedMeters = () => {
    const [meters, setMeters] = useState([]);
    const [discos, setDiscos] = useState([]);
    const [regions, setRegions] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [subdivisions, setSubdivisions] = useState([]);
    const [selectedDisco, setSelectedDisco] = useState("");
    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedDivision, setSelectedDivision] = useState("");
    const [selectedSubdivision, setSelectedSubdivision] = useState("");

    useEffect(() => {
        axiosInstance.get('/v1/all_discos').then(response => setDiscos(response.data));
        fetchMeters();
    }, []);

    useEffect(() => {
        if (selectedDisco) {
            axiosInstance.get('/v1/regions', { params: { disco_id: selectedDisco } }).then(response => setRegions(response.data));
        } else {
            setRegions([]);
            setSelectedRegion("");
        }
    }, [selectedDisco]);

    useEffect(() => {
        if (selectedRegion) {
            axiosInstance.get('/v1/divisions', { params: { region_id: selectedRegion } }).then(response => setDivisions(response.data));
        } else {
            setDivisions([]);
            setSelectedDivision("");
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (selectedDivision) {
            axiosInstance.get('/v1/subdivisions', { params: { division_id: selectedDivision } }).then(response => setSubdivisions(response.data));
        } else {
            setSubdivisions([]);
            setSelectedSubdivision("");
        }
    }, [selectedDivision]);

    const fetchMeters = async (params = {}) => {
        try {
            const response = await axiosInstance.get('/v1/meters/qc_rejections', { params });
            setMeters(response.data);
        } catch (error) {
            console.error('Error fetching meters:', error);
        }
    };

    useEffect(() => {
        const params = {
            disco_id: selectedDisco,
            region_id: selectedRegion,
            division_id: selectedDivision,
            subdivision_id: selectedSubdivision
        };
        fetchMeters(params);
    }, [selectedDisco, selectedRegion, selectedDivision, selectedSubdivision]);

    return (
        <>
            <div className="meter-filters">
                <select value={selectedDisco} onChange={(e) => setSelectedDisco(e.target.value)}>
                    <option value="">Select Disco</option>
                    {discos.map(disco => <option key={disco.id} value={disco.id}>{disco.name}</option>)}
                </select>
                <select value={selectedRegion} onChange={(e) => setSelectedRegion(e.target.value)} disabled={!selectedDisco}>
                    <option value="">Select Region</option>
                    {regions.map(region => <option key={region.id} value={region.id}>{region.name}</option>)}
                </select>
                <select value={selectedDivision} onChange={(e) => setSelectedDivision(e.target.value)} disabled={!selectedRegion}>
                    <option value="">Select Division</option>
                    {divisions.map(division => <option key={division.id} value={division.id}>{division.name}</option>)}
                </select>
                <select value={selectedSubdivision} onChange={(e) => setSelectedSubdivision(e.target.value)} disabled={!selectedDivision}>
                    <option value="">Select Subdivision</option>
                    {subdivisions.map(subdivision => <option key={subdivision.id} value={subdivision.id}>{subdivision.name}</option>)}
                </select>
            </div>
            {meters.length > 0 ? (
                <div className="meter-table-container">
                    <table className="meter-data-table">
                        <thead>
                            <tr>
                                <th>Serial No</th>
                                <th>New Meter Number</th>
                                <th>Reference No</th>
                                <th>Meter Status</th>
                                <th>Old Meter Number</th>
                                <th>Old Meter Reading</th>
                                <th>New Meter Reading</th>
                                <th>Connection Type</th>
                                <th>Bill Month</th>
                                <th>Longitude</th>
                                <th>Latitude</th>
                                <th>Meter Type</th>
                                <th>KWH MF</th>
                                <th>Sanctioned Load</th>
                                <th>Consumer Name</th>
                                <th>Consumer Address</th>
                                <th>Application No</th>
                                <th>Green Meter</th>
                                <th>Telco</th>
                                <th>SIM No</th>
                                <th>Signal Strength</th>
                                <th>Picture Upload</th>
                                <th>Previous Meter Picture</th>
                                <th>Meter Replace DateTime</th>
                                <th>No of Reset Old Meter</th>
                                <th>No of Reset New Meter</th>
                                <th>KWH T1</th>
                                <th>KWH T2</th>
                                <th>KWH Total</th>
                                <th>KVARH T1</th>
                                <th>KVARH T2</th>
                                <th>KVARH Total</th>
                                <th>MDI T1</th>
                                <th>MDI T2</th>
                                <th>MDI Total</th>
                                <th>Cumulative MDI T1</th>
                                <th>Cumulative MDI T2</th>
                                <th>Cumulative MDI Total</th>
                                <th>New KWH T1</th>
                                <th>New KWH T2</th>
                                <th>New KVARH Total</th>
                                <th>New KVARH T1</th>
                                <th>New KVARH T2</th>
                                <th>New MDI Total</th>
                                <th>New MDI T1</th>
                                <th>New MDI T2</th>
                                <th>New Cumulative MDI Total</th>
                                <th>New Cumulative MDI T1</th>
                                <th>New Cumulative MDI T2</th>
                                <th>New SIM No</th>
                                <th>Inspection</th>
                            </tr>
                        </thead>
                        <tbody>
                            {meters.map((meter, index) => (
                                <tr key={meter.id}>
                                    <td>{index + 1}</td> {/* Serial Number */}
                                    <td>{meter.NEW_METER_NUMBER}</td>
                                    <td>{meter.REF_NO}</td>
                                    <td>{meter.METER_STATUS}</td>
                                    <td>{meter.OLD_METER_NUMBER}</td>
                                    <td>{meter.OLD_METER_READING}</td>
                                    <td>{meter.NEW_METER_READING}</td>
                                    <td>{meter.CONNECTION_TYPE}</td>
                                    <td>{meter.BILL_MONTH}</td>
                                    <td>{meter.LONGITUDE}</td>
                                    <td>{meter.LATITUDE}</td>
                                    <td>{meter.METER_TYPE}</td>
                                    <td>{meter.KWH_MF}</td>
                                    <td>{meter.SAN_LOAD}</td>
                                    <td>{meter.CONSUMER_NAME}</td>
                                    <td>{meter.CONSUMER_ADDRESS}</td>
                                    <td>{meter.APPLICATION_NO}</td>
                                    <td>{meter.GREEN_METER}</td>
                                    <td>{meter.TELCO}</td>
                                    <td>{meter.SIM_NO}</td>
                                    <td>{meter.SIGNAL_STRENGTH}</td>
                                    <td>
                                        {meter.PICTURE_UPLOAD && (
                                            <a href={meter.PICTURE_UPLOAD} target="_blank" rel="noopener noreferrer">
                                                View Image
                                            </a>
                                        )}
                                    </td>
                                    <td>
                                        {meter.PREVIOUS_METER_PICTURE && (
                                            <a href={meter.PREVIOUS_METER_PICTURE} target="_blank" rel="noopener noreferrer">
                                                View Image
                                            </a>
                                        )}
                                    </td>
                                    <td>{meter.METR_REPLACE_DATE_TIME}</td>
                                    <td>{meter.NO_OF_RESET_OLD_METER}</td>
                                    <td>{meter.NO_OF_RESET_NEW_METER}</td>
                                    <td>{meter.KWH_T1}</td>
                                    <td>{meter.KWH_T2}</td>
                                    <td>{meter.KWH_TOTAL}</td>
                                    <td>{meter.KVARH_T1}</td>
                                    <td>{meter.KVARH_T2}</td>
                                    <td>{meter.KVARH_TOTAL}</td>
                                    <td>{meter.MDI_T1}</td>
                                    <td>{meter.MDI_T2}</td>
                                    <td>{meter.MDI_TOTAL}</td>
                                    <td>{meter.CUM_MDI_T1}</td>
                                    <td>{meter.CUM_MDI_T2}</td>
                                    <td>{meter.CUM_MDI_TOTAL}</td>
                                    <td>{meter.NEW_KWH_T1}</td>
                                    <td>{meter.NEW_KWH_T2}</td>
                                    <td>{meter.NEW_KVARH_TOTAL}</td>
                                    <td>{meter.NEW_KVARH_T1}</td>
                                    <td>{meter.NEW_KVARH_T2}</td>
                                    <td>{meter.NEW_MDI_TOTAL}</td>
                                    <td>{meter.NEW_MDI_T1}</td>
                                    <td>{meter.NEW_MDI_T2}</td>
                                    <td>{meter.NEW_CUM_MDI_TOTAL}</td>
                                    <td>{meter.NEW_CUM_MDI_T1}</td>
                                    <td>{meter.NEW_CUM_MDI_T2}</td>
                                    <td>{meter.NEW_SIM_NO}</td>
                                    <td>
                                          <Link to={`/inspection-details/${meter.id}`}>View Inspection</Link> {/* Add this line */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p>No meters found.</p>
            )}
        </>
    );
};

export default QCRejectedMeters;
